import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layoutDefault.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Future shock happens when we are bombarded with more information than we can process, when too much is changing too fast, and we feel overwhelmed by a feeling of malaise and confusion. But Future Shock is also an opportunity to recognize that we are quickly innovating solutions to previously unsolvable problems — problems that will allow us to build the world we want.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Future shock is the dizzying disorientation brought on by the premature arrival of the future.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`We create an environment so ephemeral, unfamiliar and complex as to threaten millions with adaptive breakdown. This breakdown is future shock.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`We may define future shock as the distress, both physical and psychological, that arises from an overload of the human organism’s physical adaptive systems and its decision-making processes. Put more simply, future shock is the human response to overstimulation.`}</p>
    </blockquote>
    <p>{`This project has started in 2016 as a Facebook group.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "728px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "163.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAhABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAXAQEBAQEAAAAAAAAAAAAAAAADBAEC/9oADAMBAAIQAxAAAAHCVqGyswFQ6KqRZajAdnXyHUyAMX//xAAfEAABBAICAwAAAAAAAAAAAAACAAEDEQQTIjISMTP/2gAIAQEAAQUClINlxqxU/wBuCi65DRtILguKyGLd4Eya6Lsj9//EABkRAAMBAQEAAAAAAAAAAAAAAAABEQIhMf/aAAgBAwEBPwHOnBNw4ZkELw//xAAYEQACAwAAAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPwGh6Ic//8QAHxAAAgIBBAMAAAAAAAAAAAAAAAERIQIQIjJxQVGR/9oACAEBAAY/AnLag5MpmXZ50c4zL9l412bVBlTaOL+FqB6//8QAHxAAAgIBBAMAAAAAAAAAAAAAAREAITFBUWGBEHGx/9oACAEBAAE/ITBuC0gW19CAmciKsinJiC2S4gAuNYZDlwYyXbACqClgG8wxS6qc3UPaVul5Hxn/2gAMAwEAAgADAAAAEKMHzvPf/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQARITH/2gAIAQMBAT8QzgScHIeELgy8F5X/xAAYEQEAAwEAAAAAAAAAAAAAAAABABEhEP/aAAgBAgEBPxBEdIlq1g3eM//EAB8QAQADAQABBQEAAAAAAAAAAAEAESExQVFhgZGxof/aAAgBAQABPxDeZt4UDm3HAMfGn7Ab8yUDfuE3BMXoUH5pCoOlTlrPqBbKV1lGGSxRBpaetQF8XEOrq/MotyJd+YUqOjFvgoigu60fSN571Cbn0ITPmf0RnCP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Future Shock book cover",
            "title": "Future Shock book cover",
            "src": "/static/a70939bbc7b770fcae438854352fcef0/83881/future-shock-cover.jpg",
            "srcSet": ["/static/a70939bbc7b770fcae438854352fcef0/4b228/future-shock-cover.jpg 200w", "/static/a70939bbc7b770fcae438854352fcef0/f629c/future-shock-cover.jpg 400w", "/static/a70939bbc7b770fcae438854352fcef0/83881/future-shock-cover.jpg 728w"],
            "sizes": "(max-width: 728px) 100vw, 728px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Future Shock book cover`}</figcaption>{`
  `}</figure></p>
    <p>{`The name pays homage to the visionary book Future Shock published in 1970. It was written by a futurist Alvin Toffler.`}</p>
    <p>{`The site has quite a neat technical setup in the background. I’ve actually liked it so much that I’ve decided to keep the repository public and write short documentation so others can create similar small-scale syndication operations.`}</p>
    <p>{`TECHNICAL DOCUMENTATION ON GITHUB`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      